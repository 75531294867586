import * as Sentry from '@sentry/gatsby';
import { errorSampleRate, sentryEnv } from './src/utils/sentry';

const SENTRY_DSN = 'https://e6c24afe1d694d2f9b1daa10965006e1@sentry.io/1228922'; // safe to commit, see https://docs.sentry.io/product/sentry-basics/dsn-explainer/#dsn-utilization
if (typeof window !== 'undefined' && !/Chrome-Lighthouse/.test(window.navigator.userAgent)) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: sentryEnv,
    // SENTRY SESSION REPLAY
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
    enableTracing: true,
    tracesSampleRate: errorSampleRate,
    integrations: [
      new Sentry.Integrations.Breadcrumbs({
        console: process.env.NODE_ENV === 'development' ? false : true
      })
    ]
  });
} else {
  console.info('Chrome-Lighthouse userAgent detected. Skipped Sentry initialization.');
}
