import * as Sentry from '@sentry/gatsby';
export const isDevMode = process.env.NODE_ENV === 'development';
export const isPreviewServer = isDevMode && !process.env.GATSBY_LOCAL_DEV;

enum SENTRY_ENV {
  DYN_ENV = 'dyn_env',
  LOCAL = 'local',
  PREVIEW = 'preview',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
  PRODUCTION = 'production'
}

export const sentryEnv = process.env.GATSBY_DYNAMIC_ENV_SUB_DOMAIN
  ? SENTRY_ENV.DYN_ENV
  : process.env.GATSBY_LOCAL_DEV
  ? SENTRY_ENV.LOCAL
  : isPreviewServer
  ? SENTRY_ENV.PREVIEW
  : process.env.GATSBY_ENVIRONMENT;

export const errorSampleRate =
  sentryEnv === SENTRY_ENV.LOCAL
    ? 0 // sample nothing on Local
    : sentryEnv === SENTRY_ENV.DYN_ENV || sentryEnv === SENTRY_ENV.STAGING || sentryEnv === SENTRY_ENV.DEVELOPMENT
    ? 1 // sample everything on testing env
    : 0.001; // sample 1% in production & preview
const sampleRate = errorSampleRate / 100;

export function acceptSentryReplay() {
  const client = Sentry.getCurrentHub().getClient();
  if (client) {
    const options: Sentry.BrowserOptions = client.getOptions();
    if (options) {
      options.replaysSessionSampleRate = sampleRate;
      options.replaysOnErrorSampleRate = errorSampleRate;
    }
    if (client.addIntegration) {
      const replay = new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true
      });

      client.addIntegration(replay);
    }
  }
}
